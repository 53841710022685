<style lang="scss" scoped>
    .page-header {
        width: 100%;
        height: 45px;
        color: #fff;
        position: fixed;
        top:0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 11;
        transition: all .3s;
        font-size: 14px;
        overflow: hidden;
        // background-image: linear-gradient(to bottom, rgb(34, 18, 133), rgba(34, 18, 130, 1));
        background-color: #fff;
        // border-radius: 0px 0px 4px 4px;
        box-shadow: 0px 2px 4px rgba(0,0,0,1);
        padding: 0 10px 0 10px;

        background-color: #0C131D;
        &.active {
            height: 72px;
        }
        .page-header_box {
            // max-width: 1200px;
            // margin: 0 auto;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .page-header_logo {
            height: 65%;
            img {
                height: 100%;
                // border-radius: 50%;
                overflow: hidden;
            }
        }

        .page-header_menu {
            height: 100%;
            // margin-left: 150px;
            &,& * {
                transition: all .3s;
            }
            .page-header_menu-box {
                display: flex;
                height: 100%;

                // opacity: 1;

                align-items: center;
                justify-content: center;
                .page-header_menu-cell {
                    // width: 100px;
                    height: 60%;
                    border-radius: 6px;
                    overflow: hidden;

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 5px;
                        cursor: pointer;
                        transition: all .3s;

                        &:hover,
                        &.active {
                            background-color: rgba(60,186,169,1);
                        }
                        &:active {
                            color: #8154fe;
                        }
                    }
                }


                .page-header_menu-language {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    height: 20px;
                    margin: 0px 5px;
                    color: #000;
                    font-size: 12px;

                    &:hover {
                        img {
                            border-color: #fff;
                        }
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        border: 1px solid transparent;
                        border-radius: 50%;
                        transition: all .3s;
                    }
                }
            }

        }
    }
    
    .page-header_menu-language-icon {
        margin-left: 10px;
        img {
            width: 32px;
            height: 32px;
            margin: 4px 4px 0 0;
        }
    }
</style>
<template>
    <div class="page-header" :class="{active: !navOpen}">
        <div class="page-header_box">
            <div class="page-header_logo font-color__theme display-flex__align-center font-weight__700 font-size__16px">
                <!-- <img :src="logoImage" alt="">  -->
                <img src="../assets/img/testpage/logo.png" alt="">
                <!-- <span class="padding__10px font-color__theme-glod">VCD</span> -->
            </div>

            <div class="page-header_menu">
                
                <div class="page-header_menu-box">
                    
                    <!-- <div v-for="(item, index) of menu" :key="index" class="page-header_menu-cell">
                            <a v-if="!item.unshow" @click="$router.push(item.router)" :class="{active: item.router == active}">{{ $t(`head.${item.langKey}`) }}</a>
                    </div> -->
                    <!-- <div v-if="language === 'zh'" class="page-header_menu-language" @click="cutLanguage('en')">
                        简体
                    </div>

                    <div v-else-if="language === 'zh-ft'" class="page-header_menu-language" @click="cutLanguage('en')">
                        繁体
                    </div>
                    <div v-else class="page-header_menu-language" @click="cutLanguage('zh')">
                        ENGLISH
                    </div> -->

                    <ContentWallet></ContentWallet>

                    <div class="page-header_menu-language-icon margin-right__10px margin-left__10px" @click="cutLanguage">
                        <img :src="languageImage" alt="">
                    </div>


                </div>
            </div>
        </div>

        
        <!-- <div style="position: absolute;z-index: -1000;opacity: 0;visibility: hidden;">
            <audio controls autoplay>
            <source src="../assets/static/go-time.mp3" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </div> -->
    </div>
</template>


<script>
import logoImage from '@/assets/img/logo.png'
import ContentWallet from "@/components/ContentWallet.vue";
import languageImage from '@/assets/img/testpage/lang.png'
export default {
    name: 'page-header',
    components: {
        ContentWallet
    },
    computed:{
        active() {
            return this.$route.fullPath
        },
        language() {
            return this.$store.state.language
        },
        navOpen() {
            if(this.active != '/') {
                return true
            }else if(this.scrolleTop == 100) {
                return true
            }else {
                return false
            }
        }
    },
    data() {
        return {
            logoImage,
            languageImage,
            menu: [{
                langKey: 'home',
                router: '/',
                id: '#',
                url: ''
            },{
                langKey: 'czzzjh',
                router: '/Join',
                id: '#Join',
                url: ''
            },{
                langKey: 'mint',
                router: '/Mint',
                id: '#Mint',
                url: '',
                unshow: true
            }],
            scrolleTop: 0
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        cutLanguage(lang) {
            this.$emit("show")
        },
        handleScroll() {
            //方法一
            var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
            
            if(top >= 100) {
                this.scrolleTop = 100
            }else {
                this.scrolleTop = top
            }
        },
    }

}
</script>